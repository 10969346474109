<template>
  <main class="wrapper">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<style lang="scss">
body, #app, .wrapper {
  height: 100%;
}

.wrapper {
  padding: 20px;
}
</style>

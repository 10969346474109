<template>
  <div class="home">
    <a href="https://dyor.io/ru/token/EQDmZYkDKYre36BeoBPXE2g2UvwoxZNoS4a6xMklDHDrdMaO?tab=advanced">
      <img src="https://cache.tonapi.io/imgproxy/-dvqA1wUPQbUW-R8nY60oqZC9MkOouWrJjuTHYBKpSQ/rs:fill:200:200:1/g:no/aHR0cHM6Ly9ldmVjb250cmFjdC5naXRodWIuaW8vd2Vic2l0ZS9pbWcvRVZFVE9LRU4tTE9HTy5wbmc.webp" />
      <p class="display-m">EveToken</p>
    </a>
    <p class="display-m">
      Restarting
      <vue-spinner size="20" color="white"/>
    </p>
    <p class="body-l">Please wait</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { VueSpinner } from 'vue3-spinners'

export default defineComponent({
  name: 'HomeView',
  components: {
    VueSpinner
  }
})
</script>

<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: 100%;

  & > .display-m {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  & > .body-l {
    opacity: 0.3;
  }

  & > a {
    position: fixed;
    top: 50px;
    min-width: 200px;
    max-width: 400px;
    width: 90%;
    min-height: 70px;
    background-color: var(--color-light-2);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: var(--color-light-4);
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;

    &  > img {
      width: 40px;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
</style>
